<template>
    <section>
        <div class="drop-active">
            <div class="upload-label-wrapper">
                <label class="italic" :for="fileUploadName">
                    <div class="img-wrap">
                        <img src="/images/upload-cloud-icon.svg" alt="upload-cloud-icon" />
                    </div>
                    <p v-if="error" class="color-error" v-text="error" />
                    {{ label }}
                </label>
            </div>
        </div>
        <vue-upload-component
            ref="upload"
            v-model="uploadedFiles"
            :headers="headers"
            :accepted="acceptedMimeTypes"
            :extensions="acceptedExtensions"
            :multiple="true"
            :post-action="postUrl"
            :drop="true"
            :drop-directory="false"
            @input-filter="inputFilter"
        />
    </section>
</template>

<script>
import VueUploadComponent from 'vue-upload-component';
export default {
    components: {
        VueUploadComponent,
    },
    props: {
        fileUploadName: {
            type: String,
            default: 'file',
        },
        acceptedExtensions: {
            type: [Array, String],
            default: '',
        },
        acceptedMimeTypes: {
            type: [Array, String],
            default: '',
        },
        postUrl: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        currentError: null,
        uploadedFiles: [],
        headers: {
            Authorization: 'Bearer ' + localStorage.relocity_access_token,
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
    }),
    computed: {
        error() {
            // Determining error message based on currentError
            switch (this.currentError) {
                case 'extension':
                    return 'Uploading files of this extension is currently not supported';
                case 'size':
                    return 'The file you are uploading is too big, please reduce the size of your file';
                case 'denied':
                    return 'The file has been denied';
                default:
                    return null;
            }
        },
    },
    methods: {
        inputFilter(newFile) {
            if (!this.$refs.upload.active) {
                // Initial set of the upload status
                this.currentError = null;
                this.$refs.upload.active = true;
            }
            if (!newFile.success && this.$refs.upload.files.length > 0 && newFile.progress < 100 && newFile.active) {
                // Filters to emit when the component has the processing file(s)
                this.$emit('processing', this.uploadedFiles);
            }
            if (newFile.success) {
                // Filters to emit when the component has the successfully uploaded file(s)
                this.$emit('success', this.uploadedFiles);
                this.$refs.upload.active = false;
            }
            if (newFile.error !== '') {
                // Sets current error to the newFile error
                this.currentError = newFile.error;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'scss/components/upload';
</style>
